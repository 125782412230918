.pop-outin {
    animation: 3s anim-popoutin ease ;
    animation-iteration-count: 1;
    position: relative;
}

@keyframes anim-popoutin {
    0% {
        color: #306387;
        transform: scale(0);
        opacity: 0;
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
        color: #306387;
        transform: scale(2);
        opacity: 1;
        text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    50% {
        color: #306387;
        transform: scale(1);
        opacity: 1;
        text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        /* animate nothing to add pause at the end of animation */
        transform: scale(1);
        opacity: 1;
        text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
}

.flipX {
    animation: 6s anim-flipX ease ;
}
@keyframes anim-flipX {
    0% {
        opacity: 0;
        transform: rotateX(90deg);
    }
    50% {
        opacity: 1;
        transform: rotateX(720deg);
    }
    100% {
        /* animate nothing to pause animation at the end */
        opacity: 1;
        transform: rotateX(720deg);
    }
}


.flipY {
    animation-name: spin, depth;
    animation-timing-function: linear;
    /*animation-iteration-count: infinite;*/
    animation-duration: 3s;
}
@keyframes spin {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(-360deg);
    }
}
@keyframes depth {
    0% {
        text-shadow: 0 0 black;
    }
    25% {
        text-shadow: 1px 0 black, 2px 0 black, 3px 0 black, 4px 0 black, 5px 0 black;
    }
    50% {
        text-shadow: 0 0 black;
    }
    75% {
        text-shadow: -1px 0 black, -2px 0 black, -3px 0 black, -4px 0 black,
        -5px 0 black;
    }
    100% {
        text-shadow: 0 0 black;
    }
}

/* lineup class and keyframes */
.lineUp {
    animation: 5s anim-lineUp ease-out ;
    animation-iteration-count: 1;
}
@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }
    20% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}