.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 0px;
}

.swiper-slide {

    width: 220px;
    max-width: 300px;
    height: 265px;

    background: #FFFFFF;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.swiper-slide img {
    display: block;
}